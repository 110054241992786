.loading-wrapper{
    position: relative;
}
.loading-wrapper-element{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: white, $alpha: 0.5);
    z-index: 90;
}
.list-input-search{
    max-width: 600px;
}

.input-textarea{
    min-height: 200px!important;
}