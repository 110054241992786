$fontpath: '../fonts';
// $img: '/web/img';

// $font: 'Open sans';

// $theme: #004578;

$red: #F5222D;
$volcano: #FA541C;
$orange: #FA8C16;
$gold: #FAAD14;
$yellow: #FADB14;
$lime: #A0D911;
$green: #52C41A;
$cyan: #13C2C2;
$blue: #1890FF;
$geekblue: #2F54EB;
$purple: #722ED1;
$magenta: #EB2F96;
$grey: #666666;

$copper-50: #ebe7e4;
$copper-200: #b45d3c;
$copper-300: #a65030;
$copper-400: #9b3c23;

$copper-button-hover: #7d381d;