@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:url('#{$fontpath}/Avenir-Medium.woff2') format('woff2'),
        url('#{$fontpath}/Avenir-Medium.woff') format('woff'),
        url('#{$fontpath}/Avenir-Medium.ttf')  format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src:url('#{$fontpath}/Avenir-Book.woff2') format('woff2'),
        url('#{$fontpath}/Avenir-Book.woff') format('woff'),
        url('#{$fontpath}/Avenir-Book.ttf')  format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:url('#{$fontpath}/Avenir-Light.woff2') format('woff2'),
        url('#{$fontpath}/Avenir-Light.woff') format('woff'),
        url('#{$fontpath}/Avenir-Light.ttf')  format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:url('#{$fontpath}/Avenir-Heavy.woff2') format('woff2'),
        url('#{$fontpath}/Avenir-Heavy.woff') format('woff'),
        url('#{$fontpath}/Avenir-Heavy.ttf')  format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:url('#{$fontpath}/Avenir-Black.woff2') format('woff2'),
        url('#{$fontpath}/Avenir-Black.woff') format('woff'),
        url('#{$fontpath}/Avenir-Black.ttf')  format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lora/v16/0QIvMX1D_JOuMwr7Iw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lora/v16/0QIhMX1D_JOuMw_LIftL.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787wsuxJBkq0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lora/v16/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFoq92nA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}