.logo {
  font-size: 26px;
  color: white;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  transition: font-size 200ms;
  text-align: center;
}
.ant-layout-sider-collapsed {
  .logo {
    font-size: 18px;
    padding: 0 5px;
  }
}
.header-menu-wrapper {
  display: flex;
  justify-content: space-between;
  > :first-child {
    flex-grow: 1;
  }
}
.header-menu-trigger-wrapper {
  background-color: white;
}
.header-menu-trigger {
  padding: 20px;
  &:hover {
    color: $blue;
  }
}
.main-content {
  padding: 15px;
}
.page-content {
  padding: 15px;
  background-color: white;
  min-height: 300px;
}
.ant-breadcrumb {
  margin-bottom: 1em;
}
