.component-sortable{}
.sortable-item{}
.sortable-ul{
    list-style: none;
    padding: 0;
}
.sortable-ul-horizontal{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 15px;
    row-gap: 30px;
}
.sortable-li{}