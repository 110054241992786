.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Avenir"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Avenir"]::before
{
    font-family: "Avenir", cursive;;
    content: "Avenir" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lora"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Lora"]::before
{
    font-family: "Lora", cursive;
    content: "Lora" !important;
}

/* Set content font-families */
.ql-font-Avenir {
  font-family: "Avenir";
}
.ql-font-Lora {
  font-family: "Lora";
}