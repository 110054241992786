.pointer {
  cursor: pointer;
}
.grab {
  cursor: move;
  cursor: url("data:image/vnd.microsoft.icon;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="),
    all-scroll;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.grabbing {
  cursor: url("data:image/vnd.microsoft.icon;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAAH/AAAB/wAAA/0AAANsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//+AB///wAf//4AH//+AD///yT/////////////////////////////8="),
    all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.pre-line {
  white-space: pre-line;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-white {
  color: white !important;
}
.text-yellow {
  color: $yellow !important;
}
.text-orange {
  color: $orange !important;
}
.small {
  font-size: 80%;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-1 {
  padding-top: 0.25em;
}
.pt-2 {
  padding-top: 0.5em;
}
.mt-1 {
  margin-top: 0.25em;
}
.mt-2 {
  margin-top: 0.5em;
}
.mt-3 {
  margin-top: 0.75em;
}
.mt-4 {
  margin-top: 1em;
}
.mr-2 {
  margin-right: 0.5em;
}
.mb-2 {
  margin-bottom: 0.5em;
}
.mb-3 {
  margin-bottom: 0.75em;
}
.mb-4 {
  margin-bottom: 1em;
}
.ml-2 {
  margin-left: 0.5em;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.text-primary {
  color: $copper-400;
}
