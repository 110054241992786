.inline-list{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    >:last-child{
        margin-right: 0;
    }
}
.inline-li{
    margin-right: 15px;
}
